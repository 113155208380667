.property__box-grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  height: 100%;
  cursor: pointer;
  /*
  .property__name
  {
  }
  .property__salabamScore
  {
  }
  .property__score_description
  {
  }
  .property__address1
  {
      line-height: 1;
      padding-top:0.5rem;
      svg
      {
          transform:translateY(-2px)
      }
  }
  .property__shortDescription
  {
      line-height: 1.25;
      opacity:0.5;
  }*/
}
.property__box-grid .property__inner {
  -webkit-box-shadow: 3px 5px 10px 0px rgba(0, 0, 0, 0.15);
          box-shadow: 3px 5px 10px 0px rgba(0, 0, 0, 0.15);
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  overflow: hidden;
  margin-bottom: 30px;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  position: relative;
}
@media (min-width: 768px) {
.property__box-grid .property__inner {
    margin-bottom: 60px;
}
}
.property__box-grid .property__thumb {
  -webkit-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
  background-size: cover;
  background-position: center center;
  width: 100%;
  position: relative;
  background-color: rgba(0, 0, 0, 0.03);
  border: 1px solid rgba(0, 0, 0, 0.125);
  height: 200px;
}
.property__box-grid .property__thumb .spinner__box {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
}
.property__box-grid .property__thumb img {
  display: none;
  width: 100%;
  visibility: hidden;
}
.property__box-grid .property__info .boxed {
  padding: 15px 20px 0 15px;
}
.property__box-grid .property__name {
  margin: 0;
  padding: 0;
  font-weight: bold;
  color: #333333;
  font-size: 18px;
  margin-bottom: 8px;
  min-height: 42px;
}
.property__box-grid .property__city {
  position: relative;
  padding-left: 22px;
  font-size: 11px;
}
.property__box-grid .property__city svg {
  width: 10px;
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.property__box-grid .property__room {
  position: relative;
  padding-left: 22px;
  font-size: 11px;
}
.property__box-grid .property__room svg {
  width: 18px;
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.property__box-grid .property__link {
  padding-left: 22px;
  color: #0077CA;
  font-size: 11px;
  text-decoration: underline;
}
.property__box-grid .property__rates {
  padding: 0;
  width: 90%;
  margin: 0 auto;
  margin-bottom: 0;
  font-size: 11px !important;
  font-weight: bold !important;
  text-transform: none !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}
.property__box-grid .property__rates .property__scoredesc {
  padding: 10px;
  width: calc((100% - 70px)/2);
  text-align: center;
}
.property__box-grid .property__rates .property__scoreicon {
  width: 70px;
  -webkit-transform: translateY(1px);
          transform: translateY(1px);
  color: #fff;
  text-align: center;
}
.property__box-grid .property__rates .property__scoreicon svg {
  width: 100%;
}
.property__box-grid .property__rates .property__scoreicon .score {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.property__box-grid .property__rates .property__scorestars {
  padding: 0;
  text-align: center;
  width: calc((100% - 70px)/2);
}
.property__box-grid .property__rates .property__scorestars svg {
  width: 11px;
  margin-right: 2px;
}
.property__box-grid .property__scorestars.starsTop {
  position: absolute;
  top: 0.85rem;
  left: 0.85rem;
  z-index: 1;
  width: auto;
}
.property__box-grid .property__scorestars.starsTop .stars {
  padding: 0 0.65rem;
  border-radius: 30px;
  background: #fff;
}
.property__box-grid .property__scorestars.starsTop .stars svg {
  width: 9px;
  height: 9px;
  -webkit-transform: translateY(-2px);
          transform: translateY(-2px);
  margin: 0 2px;
}
.property__box-grid .property__scorestars.starsTop .stars span:not(.StarKo) svg * {
  fill: #FF9F00;
}
.property__box-grid .property__amenities {
  margin-top: 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.property__box-grid .property__amenities .amenity {
  font-weight: normal;
  font-size: 12px;
  width: 50%;
  min-width: 50%;
  max-width: 50%;
  text-align: left;
  margin-bottom: 0.45rem;
  padding-right: 12px;
}
.property__box-grid .property__amenities .amenity:before {
  margin-right: 4px;
}
.property__box-grid *[lazy=loaded] .spinner__box {
  display: none;
}